import Swal from "sweetalert2";
import "./notifier.css";

const notify = (_title, text, type) => {
  let notificationText = text;
  let reloadRequired = false;

  if (text.includes("reload") || text.includes("refresh")) {
    notificationText = text;
    reloadRequired = true;
  }

  Swal.fire({
    text: notificationText,
    icon: type === "notice" ? "info" : type,
    toast: true,
    position: "top-end",
    iconColor: "white",
    showConfirmButton: reloadRequired,
    confirmButtonText: "Refresh Page",
    showCloseButton: true,
    timer: 6000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
      content: "custom-text",
    },
  }).then((result) => {
    if (result.isConfirmed && reloadRequired) {
      window.location.reload();
    }
  });
};

export default notify;
