import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
  };

  open() {
    document.getElementById("dialog").classList.remove("hidden");
  }

  close() {
    document.getElementById("dialog").classList.add("hidden");
  }

  handleBackdropClick(event) {
    if (event.target === event.currentTarget) {
      this.close();
    }
  }
}
