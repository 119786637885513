import { Controller } from "@hotwired/stimulus";
import i18n from "~/shared/i18n.js";
import Notify from "../shared/notifier";

export default class extends Controller {
  connect() {
    // Bind the handler so it can be removed later
    this.handleTrixInitialize = this.handleTrixInitialize.bind(this);

    document.addEventListener("trix-initialize", this.handleTrixInitialize);
  }

  disconnect() {
    document.removeEventListener("trix-initialize", this.handleTrixInitialize);
  }

  handleTrixInitialize() {
    const urlLinkButton = document.querySelector(".trix-button--dialog");
    const urlInput = document.querySelector('.trix-dialog input[type="url"]');

    // Define the click handler as an instance method
    const handleClick = () => {
      const urlValue = urlInput.value;

      if (urlValue && !urlValue.startsWith("https://") && !urlValue.startsWith("http://")) {
        Notify(null, i18n.t("shared.url_invalid"));
      }
    };

    // Bind the handler for later removal if needed
    this.handleClick = handleClick;

    urlLinkButton.addEventListener("click", handleClick);
  }
}
