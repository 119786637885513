import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  validate(event) {
    const validCharacters = /^[a-zA-Z0-9-]$/;
    const specialKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];

    if (!validCharacters.test(event.key) && !specialKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
