import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["otpInput", "combinedCode", "submitButton"];

  connect() {
    this.otpInputTargets.forEach((input) => {
      input.addEventListener("input", (e) => this.handleInput(e));
      input.addEventListener("keydown", (e) => this.handleKeydown(e));
    });

    // Add paste event listener to first input
    this.otpInputTargets[0].addEventListener("paste", (e) => this.handlePaste(e));

    // Setup SMS OTP auto-fill
    if ('OTPCredential' in window) {
      const form = this.element.querySelector('form');
      if (form) {
        this.setupSmsOtpAutofill(form);
      }
    }
  }

  handleInput(event) {
    const currentInput = event.target;
    const index = parseInt(currentInput.dataset.otpIndex);

    if (currentInput.value.length === 1) {
      this.updateCombinedCode();
      if (index < this.otpInputTargets.length - 1) {
        this.otpInputTargets[index + 1].focus();
      } else {
        // Last input filled, submit the form
        this.submitButtonTarget.disabled = true;
        currentInput.closest("form").requestSubmit();
      }
    }
  }

  handleKeydown(event) {
    const currentInput = event.target;
    const index = parseInt(currentInput.dataset.otpIndex);

    if (event.key === "Backspace" && currentInput.value === "" && index > 0) {
      this.otpInputTargets[index - 1].focus();
    }
    // Update combined code after a short delay to ensure the backspace has taken effect
    setTimeout(() => this.updateCombinedCode(), 0);
  }

  handlePaste(event) {
    event.preventDefault();
    const pastedData = (event.clipboardData || window.clipboardData)
      .getData("text")
      .replace(/[^0-9]/g, "") // Only keep digits
      .slice(0, this.otpInputTargets.length); // Limit to number of inputs

    this.otpInputTargets.forEach((input, index) => {
      input.value = pastedData[index] || "";
    });

    this.updateCombinedCode();

    // Focus last filled input or first empty input
    const lastFilledIndex = Math.min(pastedData.length - 1, this.otpInputTargets.length - 1);
    this.otpInputTargets[lastFilledIndex].focus();

    if (pastedData.length >= this.otpInputTargets.length) {
      this.submitButtonTarget.disabled = true;
      event.target.closest("form").requestSubmit();
    }
  }

  updateCombinedCode() {
    const code = this.otpInputTargets.map((input) => input.value || "").join("");
    this.combinedCodeTarget.value = code;
  }

  async setupSmsOtpAutofill(form) {
    try {
      const abortController = new AbortController();
      form.addEventListener('submit', () => abortController.abort());

      const content = await navigator.credentials.get({
        otp: { transport:['sms'] },
        signal: abortController.signal
      });

      if (content && content.code) {
        const otpCode = content.code;
        this.fillOtpInputs(otpCode);
        this.submitButtonTarget.disabled = true;
        form.requestSubmit();
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error setting up SMS OTP auto-fill:', error);
      }
    }
  }

  fillOtpInputs(code) {
    const digits = code.split('');
    this.otpInputTargets.forEach((input, index) => {
      input.value = digits[index] || '';
    });
    this.updateCombinedCode();
  }
}
