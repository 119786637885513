// Common styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "~/layouts/tailwind.css";
import "~/shared/styles/background_patterns.css";
import 'trix/dist/trix.css';

// Common JavaScript
import "@fortawesome/fontawesome-free/js/all.js";
import "~/shared/serviceWorkerRegisterer.js";
import "~/shared/i18n.js";
import * as IconFirst from "../packages/pf-icon/src/iconFirst.js";
import "~/shared/reComponentLoader.js";
import "~/shared/components/ThemeSwitch.bs.js";
IconFirst.addListener();

import "~/controllers/index.js";
import {Turbo} from "@hotwired/turbo-rails";
Turbo.session.drive = false;

import "~/stream_actions/index.js";
