import {StreamActions} from "@hotwired/turbo";
import Notify from "../shared/notifier";
import i18n from "~/shared/i18n.js";

StreamActions.remove_dialog = function () {
  document.getElementById("dialog").classList.add("hidden");
};

StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

StreamActions.notify = function () {
  const key = this.getAttribute("key");
  const message = this.getAttribute("message");
  let heading;

  switch (key) {
    case "success":
      heading = i18n.t("shared.notifications.success");
      break;
    case "error":
      heading = i18n.t("shared.notifications.error");
      break;
    default:
      heading = i18n.t("shared.notifications.notice");
      break;
  }

  Notify(heading, message, key);
};
