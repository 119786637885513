import {Controller} from "@hotwired/stimulus";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import {ar} from "intl-tel-input/i18n";

// Connects to data-controller="telinput"
export default class extends Controller {
  static values = {
    smsLogin: Boolean,
  };

  connect() {
    const input = document.querySelector("#phone");
    intlTelInput(input, {
      loadUtils: () => import("intl-tel-input/utils"),
      strictMode: true,
      initialCountry: "SA",
      separateDialCode: false,
      i18n: window.pupilfirst.locale === "ar" ? ar : {},
      onlyCountries: this.smsLoginValue ? ["sa"] : [],
      excludeCountries: ["il"],
      hiddenInput: function () {
        return {phone: "phone", country: "country"};
      },
    });
  }
}
